
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import LeftOutlined from "@ant-design/icons-vue/LeftOutlined";

export default defineComponent({
  components: { LeftOutlined },
  props: {
    title: String,
    backText: String,
  },
  emits: ["on-back"],
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: {},
});
