import { isNil } from "lodash";

const baseCurrencyFormatter = Intl.NumberFormat("en-US", {
  notation: "compact", // show K, M, B etc...
  unitDisplay: "short", // show € instead of EUR
  style: "currency", // format as currency
  currency: "EUR", // use EUR as base currency
  minimumFractionDigits: 3, // minimum decimal digits
});

/**
 * Display number in cs locale formatting
 * @FIXME: Update locale if we need to based on user's locale
 */
export const localeFormatterPretty = Intl.NumberFormat("cs-CZ").format;

/**
 * @example
 * localeFormatterPrettyShort(100123)
 * => 1.123k
 */
export const localeFormatterPrettyShort = (value: number) =>
  baseCurrencyFormatter.format(value).replace("€", "");

/**
 * Use en-US locale as basis for ADA currency
 * @param value
 * @returns
 *
 * @example
 * adaCurrencyFormatter(100123)
 * => 1.123k ₳
 */
export const adaCurrencyFormatter = (value: number) => {
  if (isNil(value)) return value;

  return baseCurrencyFormatter.format(value).replace("€", "") + " ₳";
};

/**
 * Convert lovelace to ADA
 */
export const lovelaceToAda = (lovelaceValue: number) => {
  if (isNil(lovelaceValue)) return lovelaceValue;

  return lovelaceValue / 1000000;
};
