import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "layout-main__header p-0" }
const _hoisted_2 = {
  key: 0,
  class: "layout-main__header-breadcrumb"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_left_outlined = _resolveComponent("left-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { class: "layout-main" }, {
    default: _withCtx(() => [
      _createElementVNode("header", _hoisted_1, [
        _renderSlot(_ctx.$slots, "custom-breadcrumb", {}, () => [
          (_ctx.backText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_a_button, {
                  class: "layout-main__header-breadcrumb-back p-0",
                  type: "link",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-back')))
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_left_outlined)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(_ctx.backText), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _renderSlot(_ctx.$slots, "custom-header", {}, () => [
          _createElementVNode("div", {
            class: _normalizeClass(["layout-main__header-container", { 'mb-5': _ctx.title }])
          }, [
            _renderSlot(_ctx.$slots, "title", {}, () => [
              (_ctx.title)
                ? (_openBlock(), _createBlock(_component_a_typography_title, {
                    key: 0,
                    level: 1,
                    class: "layout-main__header-title flex-1"
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "title-icon"),
                      _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
                    ]),
                    _: 3
                  }))
                : _createCommentVNode("", true)
            ]),
            _renderSlot(_ctx.$slots, "title-right")
          ], 2)
        ])
      ]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}