
import { defineComponent, PropType, ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import Column from "ant-design-vue/lib/table/Column";
import Pagination from "ant-design-vue/lib/pagination";
import DownOutlined from "@ant-design/icons-vue/DownOutlined";
import { useVModel } from "vue-composable";

export enum TableRowAlignment {
  TOP = "top",
  MIDDLE = "middle",
  BOTTOM = "bottom",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderVNode = (_, { attrs: { vnode } }: any) => vnode;

export type CustomTableColumItem = Partial<typeof Column>;

export const customTablePaginationSizeList = [10, 20, 30, 40];

export default defineComponent({
  components: { Pagination, DownOutlined, renderVNode },
  props: {
    columns: Array as PropType<CustomTableColumItem[]>,
    dataSource: Array,
    loading: Boolean,
    pageSize: {
      default: 20,
    },
    current: {
      default: 1,
    },
    rowClasses: {
      default: ["cursor-pointer"],
    },
    rowAlignment: {
      default: TableRowAlignment.TOP,
    },
    noInternalBorderStyle: {
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    loadMoreButton: Boolean,
    loadMoreLoading: Boolean,
  },
  emits: ["on-row-click", "change", "load-more"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const localPageSize = useVModel(props, "pageSize");
    const localCurrent = ref(props.current);

    const handleRowSelect = (rowRecord) => {
      emit("on-row-click", rowRecord);
    };

    watch(localPageSize, (newPageSize) => {
      /**
       * If the current selected page is greater than the max allowed page size
       * Fallback to max allowed page size
       */
      const maxPageSize = Math.ceil(
        (props?.dataSource?.length ?? 0) / newPageSize
      );
      if (localCurrent.value > maxPageSize) {
        localCurrent.value = maxPageSize;
      }
    });

    return {
      t,
      handleRowSelect,
      localPageSize,
      localCurrent,
      customTablePaginationSizeList,
      totalRows: computed(() => props.dataSource?.length),
    };
  },
});
