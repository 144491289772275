import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "custom-table" }
const _hoisted_2 = {
  key: 0,
  class: "text-center mt-8"
}
const _hoisted_3 = {
  key: 1,
  class: "custom-table__pagination flex justify-between"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_DownOutlined = _resolveComponent("DownOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_renderVNode = _resolveComponent("renderVNode")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_table, _mergeProps({
      "row-key": "id",
      size: "small",
      class: ["custom-table__table", { 'table-no-internal-border': _ctx.noInternalBorderStyle }],
      "row-class-name": () => [..._ctx.rowClasses, `align-${_ctx.rowAlignment}`].join(' '),
      "data-source": _ctx.dataSource,
      columns: _ctx.columns,
      "on-row-click": _ctx.handleRowSelect,
      pagination: {
        pageSize: _ctx.localPageSize,
        current: _ctx.localCurrent,
      },
      bordered: false,
      loading: _ctx.loading
    }, _ctx.$attrs, {
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', $event)))
    }), _createSlots({ _: 2 }, [
      _renderList(_ctx.$slots, (_, slot) => {
        return {
          name: slot,
          fn: _withCtx((scope) => [
            _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope || {})))
          ])
        }
      })
    ]), 1040, ["class", "row-class-name", "data-source", "columns", "on-row-click", "pagination", "loading"]),
    (_ctx.loadMoreButton && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_a_button, {
            type: "link",
            size: "small",
            loading: _ctx.loadMoreLoading,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('load-more')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Load More")) + " ", 1),
              (!_ctx.loadMoreLoading)
                ? (_openBlock(), _createBlock(_component_DownOutlined, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["loading"])
        ]))
      : (_ctx.showPagination)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_i18n_t, {
              keypath: "Show {entries} out of {total}",
              tag: "div",
              class: "custom-table__pagination-pager",
              scope: "global"
            }, {
              entries: _withCtx(() => [
                _createVNode(_component_a_dropdown, {
                  trigger: ['click'],
                  class: "custom-table__pagination-pager-dropdown"
                }, {
                  overlay: _withCtx(() => [
                    _createVNode(_component_a_menu, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customTablePaginationSizeList, (option) => {
                          return (_openBlock(), _createBlock(_component_a_menu_item, {
                            key: option,
                            class: _normalizeClass(["custom-table__pagination-pager-dropdown-item", { active: _ctx.localPageSize === option }]),
                            onClick: () => (_ctx.localPageSize = option)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(option), 1)
                            ]),
                            _: 2
                          }, 1032, ["class", "onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
                    }, [
                      _createTextVNode(_toDisplayString(_ctx.t("{pageSize} entries", { pageSize: _ctx.localPageSize })) + " ", 1),
                      _createVNode(_component_DownOutlined)
                    ])
                  ]),
                  _: 1
                })
              ]),
              total: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.totalRows), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_pagination, {
              pageSize: _ctx.localPageSize,
              "onUpdate:pageSize": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localPageSize) = $event)),
              current: _ctx.localCurrent,
              "onUpdate:current": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localCurrent) = $event)),
              size: "small",
              class: "custom-table__pagination-pagination",
              total: _ctx.totalRows
            }, {
              itemRender: _withCtx(({ page, type, originalElement }) => [
                (type === 'prev')
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      type: "link",
                      size: "small",
                      class: "mr-1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Previous")), 1)
                      ]),
                      _: 1
                    }))
                  : (type === 'next')
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 1,
                        type: "link",
                        size: "small",
                        class: "ml-1"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Next")), 1)
                        ]),
                        _: 1
                      }))
                    : (type === 'page')
                      ? (_openBlock(), _createBlock(_component_a_button, {
                          key: 2,
                          type: "link",
                          size: "small"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(page), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : (_openBlock(), _createBlock(_component_renderVNode, {
                          key: 3,
                          vnode: originalElement
                        }, null, 8, ["vnode"]))
              ]),
              _: 1
            }, 8, ["pageSize", "current", "total"])
          ]))
        : _createCommentVNode("", true)
  ]))
}