import { useMatchMedia } from "vue-composable";
import breakpoints from "../../less/breakpoints.less";

export const useBreakpoints = () => {
  const { matches: isMobileDown } = useMatchMedia(breakpoints.mobileDown);
  const { matches: isTabletDown } = useMatchMedia(breakpoints.tabletDown);
  const { matches: isLaptopDown } = useMatchMedia(breakpoints.laptopDown);
  const { matches: isDesktopDown } = useMatchMedia(breakpoints.desktopDown);

  return { isMobileDown, isTabletDown, isLaptopDown, isDesktopDown };
};
