import {
  Pools_pools_PoolItemResults_results,
  Pools_pools_PoolItemResults_results_poolDetail,
} from "@/api/pool/__generated__/Pools";
import map from "lodash/map";
import sample from "lodash/sample";
import random from "lodash/random";
import { lovelaceToAda } from "./adaFormatters";
import { decimalToPercentage } from "./numbers";
import { PoolRewards_poolRewards_PoolRewardsResults_results } from "@/api/pool/__generated__/PoolRewards";
import { PoolHistories_poolHistories_PoolHistoriesResults_results } from "@/api/pool/__generated__/PoolHistories";
import { PoolDelegators_poolDelegators_PoolDelegatorsResults_results } from "@/api/pool/__generated__/PoolDelegators";
import { PoolRelays_poolRelays_PoolRelaysResults_results } from "@/api/pool/__generated__/PoolRelays";
import { maskIp4Address, maskIp6Address, maskPort } from "./stringHelper";

const supportedHandlesKey = ["twitter", "telegram", "discord", "web"];

export const parsePoolHandlers = (
  poolDetails: Pools_pools_PoolItemResults_results_poolDetail | null
) => {
  const parsedUrls = JSON.parse(poolDetails?.url);
  const mapped = { web: parsedUrls?.main };
  return map(supportedHandlesKey, (key) => ({
    name: key,
    icon: key === "web" ? undefined : key,
    value: mapped[key] || parsedUrls?.[key],
  }));
};

/**
 * Parse pool items to locale or ada currency
 *
 * @FIXME: remove mock data
 */
export const parsePoolItem = (
  pool: Pools_pools_PoolItemResults_results | null
) => {
  if (!pool) return pool;

  const poolEpochDetail: Pools_pools_PoolItemResults_results["poolEpochDetail"] =
    pool?.poolEpochDetail;
  return {
    ...pool,
    poolEpochDetail: {
      ...poolEpochDetail,
      activeStakeAmount: lovelaceToAda(poolEpochDetail?.activeStakeAmount),
      stakeGain: sample([true, false]), // mock
      stakeLastAdd: random(1, 100000) * 100, // mock
      stakeSaturation: random(70, 100), // mock
    },
    roaPercentage: decimalToPercentage(pool?.roaPercentage),
    roaPercentageLifetime: decimalToPercentage(pool?.roaPercentageLifetime),
    luckPercentage: decimalToPercentage(pool?.luckPercentage),
    pledgeAmount: lovelaceToAda(pool?.pledgeAmount),
    date: pool?.created ? new Date(pool?.created) : "",
  };
};

export const parsePoolReward = (
  poolReward: PoolRewards_poolRewards_PoolRewardsResults_results | null
) => ({
  ...poolReward,
  activeStakeAmount: lovelaceToAda(poolReward?.activeStakeAmount),
  delegatorRewardsAmount: lovelaceToAda(poolReward?.delegatorRewardsAmount),
  luckPercentage: decimalToPercentage(poolReward?.luckPercentage),
  poolRewardsAmount: lovelaceToAda(poolReward?.poolRewardsAmount),
  roaPercentage: decimalToPercentage(poolReward?.roaPercentage),
});

export const parsePoolDelegation = (
  poolDelegation: PoolDelegators_poolDelegators_PoolDelegatorsResults_results | null
) => ({
  ...poolDelegation,
  amount: lovelaceToAda(poolDelegation?.amount),
});

export const parsePoolRelays = (
  poolRelay: PoolRelays_poolRelays_PoolRelaysResults_results | null
) => ({
  ...poolRelay,
  ip4Address: maskIp4Address(poolRelay?.ip4Address ?? ""),
  ip6Address: maskIp6Address(poolRelay?.ip6Address ?? ""),
  port: maskPort(poolRelay?.port ?? ""),
});

export const parsePoolHistories = (
  poolHistories: PoolHistories_poolHistories_PoolHistoriesResults_results | null
) => ({
  ...poolHistories,
  fixedCost: lovelaceToAda(poolHistories?.fixedCost),
  pledge: lovelaceToAda(poolHistories?.pledge),
  margin: poolHistories?.margin
    ? decimalToPercentage(poolHistories?.margin)
    : "",
});
